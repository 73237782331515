<template>
  <div class="empl-roya">
    <!-- start 条件过滤栏 -->
    <div class="df-row">
      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 职位选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.duty_no"
          placeholder="请选择员工职位"
          size="mini"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch(1)">
          查询明细
        </el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch(2)">
          查询汇总
        </el-button>
      </div>

      <!-- 导出表格按钮 -->
      <el-button
        @click="onExportExcel"
        size="mini"
        type="primary"
        :disabled="excelDisabled"
      >
        导出表格
      </el-button>
    </div>
    <!-- end 条件过滤栏 -->

    <!-- start 表格 -->
    <div class="total-table" v-show="submitData.mode == 1">
      <!-- 员工提成明细表 -->
      <el-table
        id="excel-detail"
        :data="emplRoyaList"
        size="mini"
        show-summary
        :summary-method="getSummariesDetail"
        :span-method="objectSpanMethod"
        :max-height="tableMaxHeight"
        stripe
        :cell-class-name="tableCellClassName"
        v-loading="loading"
      >
        <el-table-column label="员工" align="center" :fixed="isFixedRow">
          <template slot-scope="scope">
            <p>{{ scope.row.EmplNo }}</p>
            <p class="table-empl-name">{{ scope.row.EmplName }}</p>
            <p>{{ scope.row.DutyName }}</p>
            <p>{{ scope.row.LevelName }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="TotalRoya" label="总提成" align="center">
        </el-table-column>

        <el-table-column label="项目" width="700" align="center">
          <el-table-column
            prop="WorkItemName"
            label="项目名称"
          ></el-table-column>
          <el-table-column prop="WorkItemGravityText" label="项目类型">
          </el-table-column>
          <el-table-column prop="WorkAssignText" label="指定类型">
            <template slot-scope="scope">
              <p
                :class="
                  scope.row.WorkAssignText == '指定'
                    ? 'table-green-font'
                    : 'table-yellow-font'
                "
              >
                {{ scope.row.WorkAssignText }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="WorkPayModeText" label="消费类型">
            <template slot-scope="scope">
              <p
                :class="
                  scope.row.WorkPayModeText == '现金' ? 'table-red-font' : ''
                "
              >
                {{ scope.row.WorkPayModeText }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="WorkExpeModeText" label="消费方式">
          </el-table-column>
          <el-table-column prop="WorkItemCount" label="个数"> </el-table-column>

          <el-table-column label="业绩" align="center">
            <el-table-column prop="WorkPerfor" label="总业绩">
            </el-table-column>
            <el-table-column prop="WorkVipPayPerfor" label="划卡">
            </el-table-column>
            <el-table-column prop="WorkCashPayPerfor" label="现金">
            </el-table-column>
            <el-table-column prop="WorkOtherPayPerfor" label="其他">
            </el-table-column>
          </el-table-column>

          <el-table-column prop="WorkRatioText" label="比例"> </el-table-column>
          <el-table-column prop="WorkRoya" label="提成"> </el-table-column>
          <el-table-column prop="WorkTotalPerfor" label="汇总业绩">
          </el-table-column>
          <el-table-column prop="WorkTotalRoya" label="汇总提成">
          </el-table-column>
        </el-table-column>

        <el-table-column label="产品" align="center">
          <el-table-column prop="SellRoya" label="提成"> </el-table-column>
          <el-table-column prop="SellPerfor" label="业绩"> </el-table-column>
        </el-table-column>

        <el-table-column label="开充卡提成" align="center">
          <el-table-column prop="OpenKeepRoyaTotal" label="提成">
          </el-table-column>
          <el-table-column prop="OpenKeepPerforTotal" label="业绩">
          </el-table-column>
          <el-table-column
            prop="OpenKeepCardRoya"
            label="储值卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenKeepCardPerfor"
            label="储值卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardRoya"
            label="附卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardPerfor"
            label="附卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardRoya"
            label="疗程卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardPerfor"
            label="疗程卡业绩"
          ></el-table-column>
          <el-table-column prop="KeepRoya" label="充值提成"> </el-table-column>
          <el-table-column prop="KeepPerfor" label="充值业绩">
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- <el-table
        :data="[{id: 1}]"
        size="mini"
        stripe
      >
        <el-table-column label="员工" align="center">
          <div>{{ sumsList[0] }}</div>
        </el-table-column>
        <el-table-column label="总提成" align="center">
          <div>{{ sumsList[1] }}</div>
        </el-table-column>

        <el-table-column label="项目" width="700" align="center">
          <el-table-column label="项目名称">
            <div>{{ sumsList[2] }}</div>
          </el-table-column>
          <el-table-column label="项目类型">
            <div>{{ sumsList[3] }}</div>
          </el-table-column>
          <el-table-column label="指定类型">
            <div>{{ sumsList[4] }}</div>
          </el-table-column>
          <el-table-column prop="WorkPayModeText" label="消费类型">
            <div>{{ sumsList[5] }}</div>
          </el-table-column>
          <el-table-column prop="WorkExpeModeText" label="消费方式">
          </el-table-column>
          <el-table-column prop="WorkItemCount" label="个数"> </el-table-column>

          <el-table-column label="业绩" align="center">
            <el-table-column prop="WorkPerfor" label="总业绩">
            </el-table-column>
            <el-table-column prop="WorkVipPayPerfor" label="划卡">
            </el-table-column>
            <el-table-column prop="WorkCashPayPerfor" label="现金">
            </el-table-column>
            <el-table-column prop="WorkOtherPayPerfor" label="其他">
            </el-table-column>
          </el-table-column>

          <el-table-column prop="WorkRatioText" label="比例"> </el-table-column>
          <el-table-column prop="WorkRoya" label="提成"> </el-table-column>
          <el-table-column prop="WorkTotalPerfor" label="汇总业绩">
          </el-table-column>
          <el-table-column prop="WorkTotalRoya" label="汇总提成">
          </el-table-column>
        </el-table-column>

        <el-table-column label="产品" align="center">
          <el-table-column prop="SellRoya" label="提成"> </el-table-column>
          <el-table-column prop="SellPerfor" label="业绩"> </el-table-column>
        </el-table-column>

        <el-table-column label="开充卡提成" align="center">
          <el-table-column prop="OpenKeepRoyaTotal" label="提成">
          </el-table-column>
          <el-table-column prop="OpenKeepPerforTotal" label="业绩">
          </el-table-column>
          <el-table-column
            prop="OpenKeepCardRoya"
            label="储值卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenKeepCardPerfor"
            label="储值卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardRoya"
            label="附卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardPerfor"
            label="附卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardRoya"
            label="疗程卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardPerfor"
            label="疗程卡业绩"
          ></el-table-column>
          <el-table-column prop="KeepRoya" label="充值提成"> </el-table-column>
          <el-table-column prop="KeepPerfor" label="充值业绩">
          </el-table-column>
        </el-table-column>
      </el-table> -->
    </div>

    <div class="total-table" v-show="submitData.mode == 2">
      <!-- 员工提成汇总表 -->
      <el-table
        id="excel-summary"
        :data="emplRoyaList"
        show-summary
        :summary-method="getSummariesTotal"
        :span-method="objectSpanMethod"
        :max-height="tableMaxHeight"
        stripe
        :cell-class-name="tableCellClassName"
        v-loading="loading"
      >
        <el-table-column label="员工" align="center" :fixed="isFixedRow">
          <template slot-scope="scope">
            <p>{{ scope.row.EmplNo }}</p>
            <p class="table-empl-name">{{ scope.row.EmplName }}</p>
            <p>{{ scope.row.DutyName }}</p>
            <p>{{ scope.row.LevelName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="总提成" prop="TotalRoya" align="center" sortable>
          <template slot-scope="scoped"
            ><span class="table-red-font">{{
              scoped.row.TotalRoya
            }}</span></template
          >
        </el-table-column>

        <el-table-column label="劳动" align="center">
          <el-table-column prop="WorkTotalRoya" label="劳动总提成" sortable>
            <template slot-scope="scoped">
              <span class="table-red-font">
                {{ scoped.row.WorkTotalRoya }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            class-name="table-grey-bgcolor"
            prop="WorkAssignRoya"
            label="指定"
          >
            <template slot-scope="scoped">
              <div>{{ scoped.row.WorkAssignRoya }}</div>
              <div>({{ scoped.row.WorkAssignPerfor }})</div>
            </template>
          </el-table-column>
          <el-table-column
            class-name="table-grey-bgcolor"
            prop="WorkNotAssignRoya"
            label="轮牌"
          >
            <template slot-scope="scoped">
              <div>{{ scoped.row.WorkNotAssignRoya }}</div>
              <div>({{ scoped.row.WorkNotAssignPerfor }})</div>
            </template>
          </el-table-column>
          <el-table-column prop="WorkTotalPerfor" label="劳动总业绩" sortable>
            <!-- <template slot-scope="scoped">
              <div>{{ scoped.row.WorkNotAssignRoya }}</div>
              <div>({{ scoped.row.WorkTotalPerfor }})</div>
            </template> -->
          </el-table-column>
          <el-table-column prop="WorkSinglePerfor" label="单次">
            <template slot-scope="scoped">
              <div>{{ scoped.row.WorkSingleRoya }}</div>
              <div>({{ scoped.row.WorkSinglePerfor }})</div>
            </template>
          </el-table-column>
          <el-table-column prop="WorkPackPerfor" label="套餐">
            <template slot-scope="scoped">
              <div>{{ scoped.row.WorkPackRoya }}</div>
              <div>({{ scoped.row.WorkPackPerfor }})</div>
            </template>
          </el-table-column>
          <el-table-column prop="WorkDonaPerfor" label="赠送">
            <template slot-scope="scoped">
              <div>{{ scoped.row.WorkDonaRoya }}</div>
              <div>({{ scoped.row.WorkDonaPerfor }})</div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="产品" align="center">
          <el-table-column prop="SellRoya" label="产品总提成" sortable>
          </el-table-column>
          <el-table-column prop="SellPerfor" label="产品总业绩" sortable>
          </el-table-column>
        </el-table-column>

        <el-table-column label="开充卡" align="center">
          <el-table-column prop="OpenKeepRoyaTotal" label="开充卡总提成" sortable>
          </el-table-column>
          <el-table-column prop="OpenKeepPerforTotal" label="开充卡总业绩" sortable>
          </el-table-column>
          <el-table-column
            prop="OpenKeepCardRoya"
            label="储值卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenKeepCardPerfor"
            label="储值卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardRoya"
            label="附卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenAppoCardPerfor"
            label="附卡业绩"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardRoya"
            label="疗程卡提成"
          ></el-table-column>
          <el-table-column
            prop="OpenPackCardPerfor"
            label="疗程卡业绩"
          ></el-table-column>
          <el-table-column prop="KeepRoya" label="充值提成" sortable> </el-table-column>
          <el-table-column prop="KeepPerfor" label="充值业绩" sortable></el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <!-- start 表格 -->
  </div>
</template>

<script>
import report from "@/api/report.js";
export default {
  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      submitData: {
        // 提交数据列表
        subact: "query_data", // 接口类型
        begin_date: "", // 开始时间
        end_date: "", // 结束时间
        duty_no: "", // 职位Number
        mode: 2, // 表格类型 1员工提成明细表 2员工提成汇总表
      },
      tableData: [], // 员工明细表的合并行列表
      dutyList: [], // 职位列表
      multipleDate: [], // 日期选择器原始数据列表
      emplRoyaList: [], // 员工提成列表
      sumsList: [], // 合计行矩阵
      loading: false, // 表格加载
      isFixedRow: true, // 固定列禁用
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initConditionData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 初始化职位数据
    async initConditionData() {
      try {
        let { data } = await report.initEmplRoyaStatic({
          subact: "init_data",
        });
        this.dutyList = data.dutyList;
      } catch (e) {}
    },

    // 初始化页面表格数据
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await report.getEmplRoyaStatic(this.submitData);
        let tArray = [];

        // console.log(data.RecordList, 'ddddddd');

        data.RecordList.forEach((item, index) => {
          let { OpenAndKeepRoyaData, WorkPerforData, SellData } = item;
          // console.log(this.submitData.mode);

          // 判断当前获取的是员工提成明细，还是员工提成汇总的数据
          if (this.submitData.mode == 1) {
            let arr = {
              ...item,
              ...OpenAndKeepRoyaData,
              ...SellData,
              WorkTotalPerfor: WorkPerforData.WorkTotalPerfor,
              WorkTotalRoya: WorkPerforData.WorkTotalRoya,
              WorkPerforLength: WorkPerforData.DetailItems.length,
            };

            if (WorkPerforData.DetailItems.length == 0) {
              tArray.push(arr);
            } else {
              /* 需要将WorkPerforData.DetailI中所有的子集都抽出来并且添加祖父级上的所有元素，最后的效果是
              tArray下直接存储所有data.RecordList.WorkPerforData.DetailI的所有子集，所有需要两层循环 */
              WorkPerforData.DetailItems.forEach((item2, index2) => {
                // 这里我解析属性就行了，不用一个个字段写一遍
                arr = {
                  ...arr,
                  ...item2,
                };

                if (index2 != 0) {
                  arr.TotalRoya = 0;
                }

                // 可以不删除这些数组，这些数组已经是被解析过的了，留着多余且不太好整理数据结构我这里就删掉了
                delete arr.WorkPerforData;
                delete arr.OpenAndKeepRoyaData;
                delete arr.SellData;

                tArray.push(arr);
              });
            }
          } else {
            // 这里是员工提成汇总的数据，和明细不一样
            tArray.push({
              ...item,
              ...SellData,
              ...OpenAndKeepRoyaData,
              ...WorkPerforData,
            });
          }
        });

        this.emplRoyaList = tArray;
        // console.log(this.emplRoyaList);

        // 由于员工明细渲染表格十分复杂，需要计算合并行的矩阵，所以每次请求明细表数据都需要计算一次合并行
        this.submitData.mode == 1 ? this.twoDimensionalArray() : null;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    // 查询事件
    onSearch(mode) {
      this.submitData.mode = mode;
      this.emplRoyaList = [];
      this.initPageData();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    // 自定义二维数组（注意：这里是员工提成明细表渲染数据的重中之重，如要修改请慎重）
    twoDimensionalArray() {
      let list = this.emplRoyaList; // 当前明细表数据
      let length = list.length; // 列表长度（表格一共多少行）

      let arr = []; // 用来存储数组矩阵

      // 初始化矩阵数组
      for (let x = 0; x < length; x++) {
        arr[x] = [];
        for (var y = 0; y < 2; y++) {
          arr[x][y] = {
            rowspan: 1,
            colspan: 1,
          };
        }
      }

      let count = 0; // 计数器（合并的大行里面的第几个小行）
      let zLenght = 28; // 常量（从1开始数，表格一共多少列，这个得自己数）

      // x就是arr数组的一级下标（当前表格第几行）
      for (var x = 0; x < length; x++) {
        let WorkPerforLength = list[x].WorkPerforLength || 1; // 当前项目的长度（同一个职位同一个员工，有多少条项目记录，也就是合并的大行里面有多少个小行）

        // y就是arr数组的二级下标（当前表格第几列）
        for (var y = 0; y < zLenght; y++) {
          if ((y > 13 || y <= 1) && count == 0) {
            // 表格第1列、第13列之后的合并行处理
            // let width = list[row].items.length || 0;
            arr[x][y] = {
              rowspan: WorkPerforLength, // 同职位同员工的数据需要占用几行
              colspan: 1, // 需要占用几列
            };
          } else if ((y > 13 && count > 0) || (y <= 1 && count > 0)) {
            // 表格第1列、第13列之后被合并行占用了多少行，那么下面被占用的行也是有矩阵数组表示的
            // 那么被占用的行就要输出 { rowspan: 0, colspan: 0,} 来表示空白行不渲染
            arr[x][y] = {
              rowspan: 0,
              colspan: 0,
            };
          } else {
            // 其他行正常就是 { rowspan: 1, colspan: 1,} 表示只占用1行1列，其实就是第2列至13列我们正常输出
            arr[x][y] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        }

        count++;

        if (WorkPerforLength == count) {
          // 当表格的每一大行的所有小行都循环完了，要重置小行计数
          count = 0;
        }
      }
      this.tableData = arr;
    },

    // 自定义合并行回调函数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.submitData.mode == 1) {
        return this.tableData[rowIndex][columnIndex];
      } else {
        return { rowspan: 1, colspan: 1 };
      }
    },

    // 明细表合计
    getSummariesDetail(param) {
      return this.handleSummaries(param, 1);
    },

    // 汇总表合计
    getSummariesTotal(param) {
      return this.handleSummaries(param, 2);
    },

    // 自定义el-table合计函数
    handleSummaries(param, mode) {
      let { columns, data } = param;
      // let { mode } = this.submitData;
      let sums = [];

      // console.log(data);
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        let values = data.map((item) => Number(item[column.property]));

        // 判断 当前是员工明细表时我只合计总提成的数值，当前是员工汇总表时我合计所有行的数值，并且要保留小数点后两位数
        if (
          (mode == 1 &&
            column.property != "WorkItemName" &&
            column.property != "WorkItemGravityText" &&
            column.property != "WorkAssignText" &&
            column.property != "WorkPayModeText" &&
            column.property != "WorkExpeModeText") ||
          mode == 2
        ) {
          sums[index] = values.reduce((prev, curr) => {
            let value = parseFloat(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);

          sums[index] = parseFloat(sums[index]).toFixed(1);
        }
      });
      this.sumsList = sums;
      return sums;
    },

    // 表格每行的class名称回调
    tableRowClassName(event) {
      // return this.util.tableRowClassName(event);
    },

    // 表格每列的class名称回调
    tableCellClassName(event) {
      // return this.util.tableCellClassName(event);
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          let domName, fileName;
          if (this.submitData.mode == 1) {
            domName = "#excel-detail";
            fileName = "员工提成明细表";
          } else {
            domName = "#excel-summary";
            fileName = "员工提成汇总表";
          }

          this.util.onExportExcel(domName, fileName);
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  margin-top: 20px;
}
.empl-roya {
}
</style>
